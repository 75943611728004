import { GameListItem } from '../NewGameTypes';

export const gamesList: GameListItem[] = [
  {
    name: 'Chess',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Checkers',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Backgammon',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Mancala',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Connect Four',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Battleship',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Pool',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Darts',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Stratego',
    minTeamSize: 2,
    maxTeamSize: 2,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Monopoly',
    minTeamSize: 2,
    maxTeamSize: 8,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Risk',
    minTeamSize: 2,
    maxTeamSize: 6,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'The Settlers of Catan',
    minTeamSize: 3,
    maxTeamSize: 6,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Scrabble',
    minTeamSize: 2,
    maxTeamSize: 4,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Clue',
    minTeamSize: 3,
    maxTeamSize: 6,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Dominion',
    minTeamSize: 2,
    maxTeamSize: 4,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Ticket to Ride',
    minTeamSize: 2,
    maxTeamSize: 4,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Carcassonne',
    minTeamSize: 2,
    maxTeamSize: 6,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'both',
    isMatchesBased: false,
  },
  {
    name: 'Exploding Kittens',
    minTeamSize: 2,
    maxTeamSize: 10,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Memory',
    minTeamSize: 2,
    maxTeamSize: 10,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: true,
  },
  {
    name: 'Sagrada',
    minTeamSize: 1,
    maxTeamSize: 4,
    startingScore: 0,
    winningScore: 0,
    winningScoreEnabled: false,
    scoringSystem: 'increase',
    isMatchesBased: false,
  },
];
